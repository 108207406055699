import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MatBottomSheet } from '@angular/material/bottom-sheet';
import {
  DataAutocompleteDialogComponent,
  IDataAutocompleteDialogData,
  IDataAutocompleteDialogOptions,
  IDataAutocompleteDialogResult,
} from '../components/data-autocomplete-dialog/data-autocomplete-dialog.component';
import {
  DataSelectDialogComponent,
  IDataSelectDialogData,
  IDataSelectDialogOptions,
  IDataSelectDialogResult,
} from '../components/data-select-dialog/data-select-dialog.component';
import {
  FileUploadDialogComponent,
  FileUploadDialogData,
  FileUploadDialogOptions,
  FileUploadDialogResult,
} from '../components/file-upload-dialog/file-upload-dialog.component';
import {
  IMoneyInputDialogData,
  IMoneyInputDialogOptions,
  IMoneyInputDialogResult,
  MoneyInputDialogComponent,
} from '../components/money-input-dialog/money-input-dialog.component';
import {
  INumberInputDialogData,
  INumberInputDialogOptions,
  INumberInputDialogResult,
  NumberInputDialogComponent,
} from '../components/number-input-dialog/number-input-dialog.component';
import {
  IPromptDialogData,
  PromptDialogComponent,
} from '../components/prompt-dialog/prompt-dialog.component';
import {
  IPromptWithCheckboxDialogData,
  IPromptWithCheckboxDialogResult,
  PromptOptionalCheckboxDialogComponent,
} from '../components/prompt-optional-checkbox-dialog/prompt-optional-checkbox-dialog.component';
import {
  ITextInputDialogData,
  ITextInputDialogOptions,
  ITextInputDialogResult,
  TextInputDialogComponent,
} from '../components/text-input-dialog/text-input-dialog.component';

const confirmDialogWithCheckboxData: IPromptWithCheckboxDialogData<boolean> = {
  title: 'dialog.title.confirm',
  message: 'dialog.message.confirm',
  checked: false,
  checkboxLabel: 'dialog.checkboxLabel.label',
  actions: [
    { name: 'dialog.action.cancel', result: false },
    { name: 'dialog.action.ok', result: true, color: 'primary' },
  ],
};

const confirmDialogData: IPromptDialogData<boolean> = {
  title: 'dialog.title.confirm',
  message: 'dialog.message.confirm',
  actions: [
    { name: 'dialog.action.cancel', result: false },
    { name: 'dialog.action.ok', result: true, color: 'primary' },
  ],
};

const denyDialogData: IPromptDialogData<boolean> = {
  title: 'Warning',
  message: 'Cannot perform this action.',
  actions: [{ name: 'dialog.action.ok', result: true, color: 'primary' }],
};

@Injectable()
export class PromptDialogService {
  constructor(
    private matDialog: MatDialog,

    private readonly bottomSheet: MatBottomSheet,
  ) {}

  prompt<R = any>(data: IPromptDialogData<R>): Observable<R | undefined> {
    return this.matDialog
      .open<PromptDialogComponent, IPromptDialogData<R>, R>(PromptDialogComponent, {
        data,
      })
      .afterClosed();
  }

  deny(def?: Partial<IPromptDialogData<boolean>>): Observable<boolean> {
    const data = { ...denyDialogData, ...def };
    return this.prompt<boolean>(data).pipe(map((r) => !!r));
  }

  confirmAndCheckbox(
    options: Partial<IPromptWithCheckboxDialogData>,
  ): Observable<IPromptWithCheckboxDialogResult | undefined> {
    const data: IPromptWithCheckboxDialogData = { ...confirmDialogWithCheckboxData, ...options };
    return this.matDialog
      .open<
        PromptOptionalCheckboxDialogComponent,
        IPromptWithCheckboxDialogData,
        IPromptWithCheckboxDialogResult
      >(PromptOptionalCheckboxDialogComponent, { data })
      .afterClosed();
  }

  confirm(def?: Partial<IPromptDialogData<boolean>>): Observable<boolean> {
    const data = { ...confirmDialogData, ...def };
    return this.prompt<boolean>(data).pipe(map((r) => !!r));
  }

  autocomplete(options: IDataAutocompleteDialogOptions, value?: string | number | null) {
    const data: IDataAutocompleteDialogData = {
      title: 'Autocomplete Option',
      cancelLabel: 'Cancel',
      okLabel: 'Ok',
      ...options,
    };

    return this.matDialog.open<
      DataAutocompleteDialogComponent,
      IDataAutocompleteDialogData,
      IDataAutocompleteDialogResult
    >(DataAutocompleteDialogComponent, { data, minWidth: '400px' });
  }

  select<R = string | number | null | string[] | number[]>(options: IDataSelectDialogOptions<R>) {
    const data: IDataSelectDialogData<R> = {
      title: 'Select Option',
      cancelLabel: 'Cancel',
      okLabel: 'Ok',
      placeholder: 'Select...',
      ...options,
    };

    return this.matDialog.open<
      DataSelectDialogComponent,
      IDataSelectDialogData<R>,
      IDataSelectDialogResult<R>
    >(DataSelectDialogComponent, { data, minWidth: '400px' });
  }

  enterText(options: ITextInputDialogOptions) {
    const data: ITextInputDialogData = {
      title: 'Enter Value',
      cancelLabel: 'Cancel',
      okLabel: 'Ok',
      ...options,
    };

    return this.matDialog.open<
      TextInputDialogComponent,
      ITextInputDialogData,
      ITextInputDialogResult
    >(TextInputDialogComponent, { data, minWidth: '400px' });
  }

  enterNumber(options: INumberInputDialogOptions) {
    const data: INumberInputDialogData = {
      title: 'Enter Value',
      cancelLabel: 'Cancel',
      okLabel: 'Ok',
      ...options,
    };

    return this.matDialog.open<
      NumberInputDialogComponent,
      INumberInputDialogData,
      INumberInputDialogResult
    >(NumberInputDialogComponent, { data, minWidth: '400px' });
  }

  enterMoney(options: IMoneyInputDialogOptions) {
    const data: IMoneyInputDialogData = {
      title: 'Enter Value',
      cancelLabel: 'Cancel',
      okLabel: 'Ok',
      min: 0,
      ...options,
    };

    return this.matDialog.open<
      MoneyInputDialogComponent,
      IMoneyInputDialogData,
      IMoneyInputDialogResult
    >(MoneyInputDialogComponent, { data, minWidth: '400px' });
  }

  files(options: FileUploadDialogOptions) {
    const data: FileUploadDialogData = {
      title: 'Upload File',
      cancelLabel: 'Cancel',
      submitLabel: 'Upload',
      multiple: false,
      required: false,
      ...options,
    };

    return this.matDialog.open<
      FileUploadDialogComponent,
      FileUploadDialogData,
      FileUploadDialogResult
    >(FileUploadDialogComponent, { data, minWidth: '400px' });
  }
}
