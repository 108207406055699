<h1 mat-dialog-title>{{ data.title | l }}</h1>
<form mat-dialog-content [formGroup]="control">
  {{ data.message | l }}
  <mat-checkbox formControlName="checked" [checked]="true">{{ data.checkboxLabel }}</mat-checkbox>
</form>
<div mat-dialog-actions align="end">
  <button
    *ngFor="let action of data.actions"
    mat-flat-button
    (click)="selectAction(action)"
    [color]="action.color"
  >
    {{ action.name | l }}
  </button>
</div>
