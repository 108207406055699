import { InjectionToken } from '@angular/core';
import { PointInput } from '@x/schemas/ecommerce';

export const MAP_CONFIG = new InjectionToken<MapConfig>('MAP_CONFIG');

export interface IMapIcon {
  url: string;
  size: { width: number; height: number };
}

export interface MapConfig {
  markerIconUser: IMapIcon;
  markerIconRegion: IMapIcon;
  polygonTheme: google.maps.PolygonOptions;
  theme: google.maps.MapTypeStyle[];
  defaultCoordinates: PointInput;
  iconMap: { [key: string]: IMapIcon };
}
