import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPromptDialogActionData } from '../prompt-dialog/prompt-dialog.component';

export interface IPromptWithCheckboxDialogData<R = boolean> {
  title: string;
  message: string;
  checked: boolean;
  checkboxLabel: string;
  actions: IPromptDialogActionData<R>[];
}

export interface IPromptWithCheckboxDialogResult {
  result: boolean;
  checked: boolean;
}

@Component({
  selector: 'x-prompt-optional-checkbox-dialog',
  templateUrl: './prompt-optional-checkbox-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./prompt-optional-checkbox-dialog.component.scss'],
})
export class PromptOptionalCheckboxDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<PromptOptionalCheckboxDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IPromptWithCheckboxDialogData,
  ) {}

  control = new UntypedFormGroup({
    checked: new UntypedFormControl(this.data.checked ?? false, Validators.required),
  });

  selectAction(action: IPromptDialogActionData) {
    this.dialogRef.close({ result: action.result, checked: this.control.value.checked });
  }
}
